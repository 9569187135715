<template>
  <ion-grid class="full-height">
    <ion-row class="full-height">
      <ion-col size-md="8" offset-md="2" size="12" offset="0" size-lg="6" offset-lg="3" size-xl="4" offset-xl="4" class="bg-white border-lr-muted padding-bottom-eula">
        <ion-content>
          <!-- {{EULABody}} -->
          <div class="end-user-agreement pad-lr-twenty" @scroll="onEULAScroll">
            <h4>
              We've updated our End User License Agreement.
            </h4>
            <ion-row class="prime-scroll-callout display-flex valign margin-bottom-ten">
              <ion-col style="max-width: 40px;">
                <svg width="30px" height="40px" viewBox="0 0 30 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <path d="M8.36975395,12 C7.62173813,12 6.90472296,12.277 6.35171126,12.781 C6.31271043,12.815 6.27770969,12.854 6.24570901,12.894 L0.221581568,20.373 C-0.110425456,20.785 -0.0644244824,21.384 0.32658379,21.74 L6.35171126,27.219 C6.90472296,27.723 7.62173813,28 8.36975395,28 L18.1409607,28 C19.1659824,28 20,27.185 20,26.182 L20,13.818 C20,12.815 19.1659824,12 18.1409607,12 L8.36975395,12 Z" id="path-1" />
                  </defs>
                  <g id="note-tag" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1" />
                    </mask>
                    <use id="🎨-Icon-Сolor" fill="#F7B500" fill-rule="nonzero" transform="translate(10.000000, 20.000000) rotate(-180.000000) translate(-10.000000, -20.000000) " xlink:href="#path-1" />
                  </g>
                </svg>
              </ion-col>
              <ion-col>
                <span>Scroll to the bottom of the license agreement before affirming below.</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <hr />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col v-if="EULABody">
                <div v-dompurify-html="EULABody.templateMessages[0].body"></div>
              </ion-col>
            </ion-row>
          </div>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="pad-lr-twenty">
            <ion-row>
              <ion-col></ion-col>
              <ion-col size="auto">
                <button class="prime-button button-secondary">
                  <router-link to="/logout">Logout</router-link>
                </button>
              </ion-col>
              <ion-col size="auto" class="margin-left-ten">
                <button
                  :class="{
                    'prime-button pending': !isConfirmButtonEnabled
                  }"
                  @click="confirmEULA"
                  class="prime-button"
                  v-tooltip.right="!isConfirmButtonEnabled ? 'Please review the End User License Agreement.' : ''"
                >
                  Agree
                </button>
              </ion-col>
            </ion-row>
          </ion-toolbar>
        </ion-footer>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { send as httpSend } from "@/services/Api";

export default {
  name: "EULA",
  data() {
    return {
      EULABody: undefined,
      isConfirmButtonEnabled: false
    };
  },
  created() {
    this.getEULA();
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    }
  },
  methods: {
    onEULAScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 10 >= scrollHeight) {
        this.isConfirmButtonEnabled = true;
      }
    },
    confirmEULA: function() {
      if (this.isConfirmButtonEnabled) {
        const method = "put";
        const path = document.config.confirmEula;
        const payload = {
          accountId: this.currentProfessional.account.id,
          id: this.EULABody.id,
          templateId: this.EULABody.id
        };

        httpSend({ path, method, authToken: true, payload })
          .then(() => {
            this.$store.commit("currentProfessional/isEulaRead", true);
          })
          .catch(error => {
            this.$ionic.toastController
              .create({
                header: "Could not confirm the End User License Agreement. Please try again later.",
                message: error,
                duration: 5000,
                position: "top"
              })
              .then(m => m.present());
          });
      }
    },
    getEULA: function() {
      const method = "get";
      const path = document.config.getEula;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.EULABody = result.data;
        })
        .catch(error => {
          khanSolo.log(error);
        });
    }
  }
};
</script>
<style scoped>
.end-user-agreement {
  height: 100%;
  overflow: auto;
  background-color: #fff;
  box-shadow: var(--prime-card-shadow);
}
.padding-bottom-eula {
  padding-bottom: 60px;
}
.prime-scroll-callout {
  font-weight: 500;
}
button.prime-button.pending {
  background-color: var(--ion-color-medium);
  box-shadow: 0 1px 3px 0px rgba(15, 93, 184, 0);
}
</style>
