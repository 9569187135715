<template>
  <div v-if="!isEULARead" class="bg-grey-one full-height">
    <EULA />
  </div>
  <div v-else class="full-height">
    <ion-split-pane content-id="main-content" when="md">
      <!--  our side menu  -->
      <ion-menu content-id="main-content" type="overlay" side="start" swipe-gesture="false">
        <ion-header>
          <ion-grid>
            <ion-row class="ion-align-items-center">
              <ion-col size-xs="5" push-xs="7" size-md="12" push-md="0">
                <div class="valign prime-main-menu-logo-container">
                  <img class="prime-main-menu-logomark" src="../assets/MyPCP-logo-mark.svg" />
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-header>
        <div class="prime-main-menu">
          <ion-list v-if="orgIdState || !isSecurityData" class="display-flex valign centerItems">
            <ion-menu-toggle auto-hide="false">
              <router-link
                id="conversations"
                :to="{ name: 'patientView' }"
                v-if="$can(I.VIEW_PATIENT)"
                exact
              >
                <div class="prime-main-menu-item" v-tooltip.right="'Conversations'">
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto" offset-md="3.5">
                      <IconChatBubble class="prime-main-menu-icon" />
                    </ion-col>
                    <ion-col class="mobile pad-right-fifteen pad-left-five text-white text-left">
                      Conversations
                    </ion-col>
                  </ion-row>
                </div>
              </router-link>
              <router-link id="tasks" :to="{ name: 'tasksView' }" v-if="$can(I.VIEW_PATIENT)">
                <div class="prime-main-menu-item" v-tooltip.right="'Tasks'">
                  <ion-row class="ion-align-items-center">
                    <ion-col size="auto" offset-md="3.5">
                      <IconCheckCircle class="prime-main-menu-icon" />
                    </ion-col>
                    <ion-col class="mobile pad-right-fifteen pad-left-five text-white text-left">
                      Tasks
                    </ion-col>
                  </ion-row>
                </div>
              </router-link>
              <router-link
                id="programs"
                :to="{ name: 'ProgramsList' }"
                v-if="$can(I.VIEW_PROGRAMS)"
              >
                <div class="prime-main-menu-item" v-tooltip.right="'Programs'">
                  <ion-row>
                    <ion-col size="auto" offset-md="3.5">
                      <IconPrograms class="prime-main-menu-icon" />
                    </ion-col>
                    <ion-col class="mobile pad-right-fifteen pad-left-five text-white text-left">
                      Programs
                    </ion-col>
                  </ion-row>
                </div>
              </router-link>
              <router-link
                id="patientRoster"
                :to="{ name: 'rosterView' }"
                v-if="$can(I.VIEW_PATIENT)"
              >
                <div class="prime-main-menu-item" v-tooltip.right="'Patient Roster'">
                  <ion-row>
                    <ion-col size="auto" offset-md="3.5">
                      <IconBulletList class="prime-main-menu-icon" />
                    </ion-col>
                    <ion-col class="mobile pad-right-fifteen pad-left-five text-white text-left">
                      Patient Roster
                    </ion-col>
                  </ion-row>
                </div>
              </router-link>
            </ion-menu-toggle>
          </ion-list>
        </div>
      </ion-menu>
      <!-- the main content -->
      <ion-router-outlet id="main-content" animated="false">
        <router-view :key="$store.state.routerKey" />
      </ion-router-outlet>
    </ion-split-pane>
    <data-dog
      :application_id="applicationId"
      :client_token="clientToken"
      :environment="environment"
      :organization_name="organizationName"
      :user_email="userEmail"
      :user_role="userRole"
    ></data-dog>
  </div>
</template>

<script>
import EULA from "@/components/Global/EULA";
import IconBulletList from "@/components/Global/Icons/IconBulletList";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import IconPrograms from "@/components/Global/Icons/IconPrograms";
import auth from "@/utils/Auth";
import { datadogLogs } from "@datadog/browser-logs";
import { mapGetters } from "vuex";
export default {
  name: "NavHome",
  data() {
    return {
      EULABody: undefined,
      isConfirmButtonEnabled: false,
      isSecurityData: this.securityData || auth.getSecurityData()
    };
  },
  components: {
    IconChatBubble,
    IconCheckCircle,
    IconBulletList,
    IconPrograms,
    EULA
  },

  computed: {
    ...mapGetters({
      orgIdState: "organizations/getOrganizationId",
      securityData: "security/getSecurityData"
    }),

    isEULARead() {
      return this.$store.getters["currentProfessional/isEulaRead"];
    },

    applicationId() {
      return document.config.applicationId;
    },

    clientToken() {
      return document.config.clientToken;
    },

    environment() {
      return document.config.environment;
    },

    organizationName() {
      return this.$authState.claims() && this.$authState.claims().practice;
    },

    userEmail() {
      return this.$authState.claims() && this.$authState.claims().sub;
    },

    userRole() {
      return this.$authState.claims() && this.$authState.claims().role;
    }
  },

  async created() {
    const token = await this.$authState.accessToken();
    const url = document.config.webSocketUrl;
    await this.$store.dispatch("currentProfessional/update");
    token && (await this.$store.dispatch("websocket/connect", { url, token }));

    datadogLogs.setUser({
      organization: this.organizationName,
      email: this.userEmail,
      role: this.userRole
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.padding-bottom-eula {
  padding-bottom: 80px;
}

.split-pane-main {
  overflow: hidden;
}
.menu-title {
  font-weight: bold;
  margin-left: 15px;
  font-size: 18px;
}

/* ionic ion-menu styling */

.split-pane-md.split-pane-visible > .split-pane-side {
  border: none;
  min-width: 80px;
  max-width: 80px;
}

.prime-main-menu-logotype {
  margin-left: 10px;
  margin-right: 10px;
}
.prime-main-menu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: -109px;
  background: var(--ion-color-primary);
}
.prime-main-menu ion-list {
  flex-grow: 1;
}
/* .prime-main-menu .prime-main-menu-item {
  display: block;
  width: 50px;
  margin: 15px;
  text-align: center;
  background: rgba(11, 71, 142, 0.001);
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background 0.2s ease-in-out;
} */
.prime-main-menu .prime-main-menu-item.has-counter {
  padding: 10px;
}
.prime-main-menu a {
  text-decoration: none;
}
.prime-main-menu a svg {
  fill: var(--ion-color-white);
  opacity: 0.5;
  display: block;
  margin: 0 auto;
  transition: opacity 0.2s ease-in-out;
}
.prime-main-menu .prime-main-menu-item-count {
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  background: var(--ion-color-white);
  border-radius: 20px;
  padding: 0 8px;
  margin: 3px auto 0;
  opacity: 0.5;
}
.prime-main-menu a.router-link-active ion-label {
  color: var(--ion-color-medium-contrast);
}
.prime-main-menu a.router-link-active .prime-main-menu-item {
  background: rgba(11, 71, 142, 0.5);
}
.prime-main-menu a.router-link-active .prime-main-menu-item-count {
  opacity: 1;
}
.prime-main-menu a.router-link-active svg {
  opacity: 1;
}
.prime-main-menu a:hover .prime-main-menu-item {
  background: rgba(11, 71, 142, 0.5);
}
.prime-main-menu-icon {
  width: 20px;
  margin-left: 14px;
  margin-right: 14px;
}

ion-col:first-child > ion-card {
  margin-left: 0;
}
/* ion-col:last-child > ion-card {
  margin-right:0;
} */

ion-router-outlet {
  overflow-y: scroll;
}
.end-user-agreement {
  height: 100%;
  overflow: auto;
  background-color: #fff;
  /* border-radius: 8px;
    margin-bottom: 20px; */
  box-shadow: var(--prime-card-shadow);
}
.prime-scroll-callout {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
}
button.prime-button.pending {
  background-color: var(--ion-color-medium);
  box-shadow: 0 1px 3px 0px rgba(15, 93, 184, 0);
}

@media (max-width: 767px) {
  ion-menu-toggle {
    width: 80%;
  }
}
</style>
